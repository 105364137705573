/* eslint-disable react-hooks/rules-of-hooks */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
const token = process.env.REACT_APP_AUTH_TOKEN;
const token2 = localStorage.getItem("token");
// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  reducerPath: 'pmbWebApi',
  baseQuery: fetchBaseQuery({
    baseUrl: "https://pmb.tm30.net/api/v1" || "http://localhost:6689/api/v1",
    // baseUrl: "http://localhost:6689/api/v1",
    prepareHeaders: headers => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      // const clientJwtToken = "production_PNoljC_y4iH._qt4FUkofEK.iB_iMHbkBzL5HVVmqfFV93F2wy" || "staging_vMjgDLvH8oOxoYO1cvqbgAmrHSAbFlTW_igRu0f7aOb4_ie7Rr";
      const clientJwtToken = token || "prod_pmb_A1C35C8556819AB5C168CAF5AA565";
      // const clientJwtToken ="staging_vMjgDLvH8oOxoYO1cvqbgAmrHSAbFlTW_igRu0f7aOb4_ie7Rr";
      if (clientJwtToken) {
        headers.set('authorization', `Bearer ${token2}`);
        headers.set("client-id", `${clientJwtToken}` );
        //set domain as header
      }
      return headers;
    },
  }),
  endpoints: () => ({}),
});
